.area-summary-bar.ui-toolkit {
  display: flex;
  justify-content: center;
  align-items: center;

  .area-summary-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 14px;
    margin-bottom: 0;
    width: 100%;
    max-width: 1200px;
  }

  h1 {
    font-family: $orderly-font-bold;
    font-size: 32px;
  }

  .score {
    display: flex;
    justify-content: space-between;
    align-items: center;


    .numeric-score {
      display: flex;
      align-items: flex-end;
      margin-right: 14px;

      .actual-score {
        font-size: 32px;
        font-family: $orderly-font-bold;
        color: $orderly-toolkit-success;
      }

      .max-score {
        font-size: 14px;
        font-family: $orderly-font-medium;
      }
    }

    .visual-score {
      text-align: right;

      svg {
        width: 80px;
        height: 42px;

        path {
          fill: $orderly-toolkit-success;
        }
      }

      .score-change {
        color: $orderly-toolkit-success;
        font-family: $orderly-font-regular;
        font-size: 16px;
        display: flex;
        justify-content: flex-end;
        align-items: center;

        svg {
          height: 15px;
          width: 20px;
          margin: 3px;
        }

        &.score-change-direction-down {
          color: $orderly-toolkit-red;

          svg {
            transform: rotate(180deg);

            path {
              fill: $orderly-toolkit-red;
            }
          }
        }
      }
    }

    &.score-0 {
      .visual-score .score-pie {
        svg {
          path {
            fill: $orderly-toolkit-red;
          }
        }
      }

      .actual-score {
        color: $orderly-toolkit-red;
      }
    }
  }
}
