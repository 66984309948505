.score-value-metric.ui-toolkit {
  background-color: $orderly-toolkit-grey;
  margin: 14px 0px 0px 0px;;
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: left;
  padding: 14px;
  min-height: 36px;
  overflow-x: auto;
  font-family: $orderly-font-bold;
  font-size: 18px;
  box-shadow: 0 2px 2px #00000029;
  color: $orderly-toolkit-black;

  input {
    font-size: 18px;
    font-family: $orderly-font-bold;
    width: fit-content;
  }

  .tooltip-container {
    input {
      font-size: 14px;
      font-family: $orderly-font-regular;
    }
  }

  .list-lable {
    display: flex;
    flex-direction: row;
    align-items: center;

    .list-table-container {
      display: flex;
    }
  }

  .score-holder {
    display: flex;
    align-items: center;
    text-align: right;

    .score {
      font-family: $orderly-font-regular;
      font-size: 20px;
      margin: 0 14px;
      display: flex;

      .variable-selector {
        max-width: 120px;
        margin: 0 5px;

        &.total {
          width: 120px !important;
          margin: 0 5px !important;
          font-family: "tthoves-regular";
          font-size: 16px;
          height: 20px;
        }
      }

      .increase-good {
        width: 25px;
      }

      .delete {
        width: 20px !important;
        height: 20px !important;
        margin-left: 10px;
      }

      .score-total {
        font-size: 16px;
        display: flex;

        input {
          width: 25px;
        }
      }
    }

    .changes {
      min-width: 85px;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      font-size: 0.8em;

      .change {
        font-weight: bold;
        font-size: 24px;

        svg {
          width: 20px;
          height: 10px;
          margin-bottom: 5px;
          margin-left: 10px;
        }

        &.change-direction-increase {
          fill: $orderly-toolkit-success;

          &.change-good-false {
            fill: $orderly-toolkit-red;
          }
        }

        &.change-direction-decrease {
          transform: rotate(180deg);
          fill: $orderly-toolkit-red;

          &.change-good-false {
            fill: $orderly-toolkit-success;
          }
        }

        &.change-direction-none {
          display: none;
        }
      }

      .change-value {
        &.change-direction-increase {
          color: $orderly-toolkit-success;

          &.change-good-false {
            color: $orderly-toolkit-red;
          }
        }

        &.change-direction-decrease {
          color: $orderly-toolkit-red;

          &.change-good-false {
            color: $orderly-toolkit-success;
          }
        }

        &.change-direction-none {
          color: $orderly-toolkit-black;
        }
      }
    }
  }
}
