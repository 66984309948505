.layout {
  display: flex;
  justify-content: stretch;

  .side- {
    flex-grow: 0;
    flex-shrink: 0;
  }

  .layout-contents {
    flex-grow: 1;
    flex-shrink: 1;
    max-width: 100%;

    header .full- {
      .nav-item {
        width: 160px;
        margin: 0;
        height: 100%;
        cursor: pointer;
        display: flex;
        justify-content: center;

        &:disabled {
          cursor: default;
        }
      }

      .sub-nav-content {
        display: none;
        position: absolute;
        left: 0;
        width: 100%;
        z-index: 1;
      }

      .sub-nav:hover .sub-nav-content {
        display: flex;
        flex-direction: column;
      }

      .sub-nav {
        position: relative;

        .sub-nav-content {
          .nav-item {
            width: 250px;
            justify-content: flex-start;

            &:last-child {
              border-radius: 0 0 5px 5px;
            }
          }
        }
      }
    }

    @media only screen and (max-width: 500px) {
      overflow-x: hidden;
    }
  }
}
