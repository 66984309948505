.search.ui-toolkit{
    display:flex;
    justify-content: flex-end;
    input{
        background: $orderly-toolkit-grey;
        border:none;
        border-bottom:1px solid $orderly-toolkit-grey;
        height:48px;
        max-width:220px;
        flex-grow:1;
        margin-bottom: 15px;
        padding: 0 20px;

        &:focus, &:active{
            border-bottom:1px solid $orderly-toolkit-black;
            outline:none;
        }
    }

    button{
        background-color: $orderly-toolkit-black;
        color:$orderly-toolkit-white;
        border:none;
        height:49px;
        width:105px;
        cursor:pointer;


        &:disabled{
            opacity: 0.5;
            cursor:default;
        }
    }
}