﻿.scoring-items {
  &.padding .input.ui-toolkit input {
    border: 1px solid #d3d3d3;
    height: 35px;
    width: auto;
    background-color: white;
  }

  &.form-action {
    display: flex;

    .button.ui-toolkit {
      padding: 0;
      margin: 0;
    }

    button {
      background-color: black !important;
      margin-left: 5px;
      height: 35px;
    }

    .cancel-btn {
      button {
        background-color: white !important;
        color: black;
        border: 1px solid black !important;
        height: 35px;
      }
    }
  }

  .actions {
    display: flex;
    flex-direction: column;
    justify-content: flex-start !important;

    button {
      display: flex;
      flex-direction: row-reverse;
      justify-content: space-between;
      margin: 7px 0;
      cursor: pointer;
      border: none;
      background-color: black !important;
      color: white;
      padding: 7px 14px;
      align-items: center;
      width: 30%;
      margin-bottom: 15px;

      .plus-icon {
        color: white;
        font-size: 1.25em;
        z-index: 2;
        position: relative;
      }

      .circle-plus-icon {
        z-index: 1;
        position: absolute;
        top: 1px;
        left: -7px;
        height: 20px;
        width: 20px;
        color: white;
        border: white solid 2px;
        border-radius: 100%;
        fill: none;
      }

      .label {
        flex-grow: 1;
        text-align: center;
      }
    }

    .bold-text {
      font-weight: bold;
    }
  }

  padding-left: 45px !important;

  .dashboard.ui-toolkit .dashboard-wrapper .padding {
    height: 45px !important;
  }
}
