
.cron-dropdown.ui-toolkit {
  display: flex;

  &.grey {
    background: $orderly-toolkit-grey;
    padding: 8px;
    border-radius: 3px;

    select {
      background: $orderly-toolkit-grey;
      font-size: 15px;
    }
  }

  select {
    background: $orderly-toolkit-white;
    border: none;
    height: 30px;
    width: 100%;
    cursor: pointer;
    outline: none;
  }
}
