header.menu-bar.ui-toolkit {
  max-height: 104px;
  background-color: $orderly-toolkit-black2;
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  min-height: 60px;

  .menu-button {
    border: none;
    background-color: $orderly-toolkit-black2;
    fill: $orderly-toolkit-white;
    padding-left: 14px;
    padding-right: 14px;

    @media only screen and (min-width: 800px) {
      display: none;
    }
  }

  .flex-container {
    display: flex;
  }

  .logo {
    display: flex;
    align-items: center;

    .img-logo {
      cursor: pointer;
    }

    @media only screen and (max-width: 800px) {
      .img-logo {
        width: 150px;
        padding: 10px;
        margin-right: 10px;
      }
    }

    @media only screen and (min-width: 800px) {
      margin-left: 36px;
    }

    @media only screen and (min-width: 1200px) {
      margin-left: 75px;
      margin-top: 5px;
    }
  }

  .counter-balance { //balance button
    min-width: 60px;

    @media only screen and (min-width: 800px) {
      display: none;
    }
  }

  .question-mark {
    display: flex;
    align-items: center;
    padding: 10px;

    img {
      max-width: 35px;
      max-height: 35px;
      border-radius: 50%;
      cursor: pointer;
    }
  }

  .full-menu.ui-toolkit {
    color: $orderly-toolkit-white;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media only screen and (max-width: 800px) {
      display: none;
    }

    .avatar {
      height: 37px;
      font-size: 12px;
      margin-right: 25px;

      .avatar-circle {
        height: 37px;
        width: 37px;
        color: $orderly-toolkit-black2;
      }

      .company-logo {
        height: 14px;
        width: 14px;
      }
    }

    .wave-score {
      width: 47px;
      margin-right: 25px;

      svg {
        width: 38px;
      }
    }

    .details {
      font-size: 12px;
      margin-right: 25px;

      .owner-details {
        display: flex;
        justify-content: space-between;
      }

      .label,
      .tenant {
        font-family: $orderly-font-bold;

        a {
          color: $orderly-toolkit-yellow;
          font-weight: normal;
        }
      }

      .phoneNumber,
      .email {
        font-family: $orderly-font-regular;

        a {
          color: $orderly-toolkit-yellow;
          font-weight: normal;
        }
      }
    }

    .logout-button {
      background-color: $orderly-toolkit-black2;
      border: none;
      cursor: pointer;
    }
  }
}

.side-menu.ui-toolkit {
  z-index: 100;
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background: $orderly-toolkit-mask;

  .side-menu {
    padding-top: 18px;
    z-index: 101;
    min-height: 100vh;
    width: 350px;
    position: absolute;
    top: 0;
    left: 0;
    background: $orderly-toolkit-black3;
    box-shadow: 0 1px 2px $orderly-toolkit-mask;

    .sub-nav-item {
      padding-left: 52px;
    }

    .no-click {
      cursor: default;

      &:hover,
      &:focus {
        background-color: $orderly-toolkit-black2;
      }
    }
  }

  @media only screen and (min-width: 800px) {
    display: none;
  }
}

.full-menu.ui-toolkit {
  display: none;

  @media only screen and (min-width: 800px) {
    display: flex;
    align-items: stretch;
  }
}

.help-logo {
  display: flex;
  align-items: center;
  padding: 18px;
  margin-right: 6px;
  width: 42px;
}
