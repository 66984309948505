$orderly-toolkit-success: #28b440;
$orderly-toolkit-white: #fff;
$orderly-toolkit-black: #2e2d2c;
$orderly-toolkit-black2: #292827;
$orderly-toolkit-black3: #171717;
$orderly-toolkit-red: #e04e39;
$orderly-toolkit-yellow: #f2cd00;
$orderly-toolkit-brown: #a99000;
$orderly-toolkit-grey: #f2f2f2;
$orderly-toolkit-grey2: #f8f8f8;
$orderly-toolkit-mask: rgba(46, 45, 44, 0.4);

@font-face {
  font-family: 'tthoves-light';
  src: url("/assets/fonts/TT Hoves Light.otf") format('opentype');
}

@font-face {
  font-family: 'tthoves-regular';
  src: url("/assets/fonts/TT Hoves Regular.otf") format('opentype');
}

@font-face {
  font-family: 'tthoves-medium';
  src: url("/assets/fonts/TT Hoves Medium.otf") format('opentype');
}

@font-face {
  font-family: 'tthoves-bold';
  src: url("/assets/fonts/TT Hoves Bold.otf") format('opentype');
}

@font-face {
  font-family: 'tthoves-demibold';
  src: url("/assets/fonts/TT Hoves DemiBold.otf") format('opentype');
}

$orderly-font-regular: 'tthoves-regular';
$orderly-font-bold: 'tthoves-bold';
$orderly-font-medium: 'tthoves-medium';
$orderly-font-light: 'tthoves-light';
$orderly-font-demibold: 'tthoves-demibold';

body {
  font-family: $orderly-font-regular;
  font-size: 16px;
  color: $orderly-toolkit-black;
  margin: 0;
}

input {
  font-family: $orderly-font-light;
  font-size: 16px;
}

select {
  font-family: $orderly-font-regular;
  font-size: 16px;
}

button {
  font-family: $orderly-font-medium;
  font-size: 16px;
}

h1 {
  font-family: $orderly-font-bold;
  font-size: 32px;
}

h2 {
  font-family: $orderly-font-bold;
  font-size: 16px;
}

a {
  text-decoration: none;

  &:hover,
  &:visited,
  &:focus {
    color: $orderly-toolkit-black;
    text-decoration: none;
  }
}

@import "./components/navItem.scss";
@import "./components/input.scss";
@import "./components/textArea.scss";
@import "./components/button.scss";
@import "./components/areaSummaryBar.scss";
@import "./components/pagination.scss";
@import "./components/tableAddEntry.scss";
@import "./components/search.scss";
@import "./components/modal.scss";
@import "./components/promptModal.scss";
@import "./containers/list.scss";
@import "./containers/form.scss";
@import "./containers/page.scss";
@import "./containers/table.scss";

label {
  text-align: left;
  justify-self: flex-start;
  display: block;
  width: 100%;
}
