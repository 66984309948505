﻿.textarea.ui-toolkit {
  text-align: unset;
  display: block;
  height: 150px;
  width: 100%;
  box-sizing: border-box;

  textarea {
    background: $orderly-toolkit-grey;
    border: none;
    height: 150px;
    width: 100%;
    padding: 10px 20px;
    box-sizing: border-box;
    font-family: "tthoves-light";
    font-size: 16px;
    resize: none;
  }
}

.form.ui-toolkit .form-wrapper .fields textarea {
  display: block;
  margin-bottom: 21px;
  margin-top: 7px;
}
