.score-list-metric.ui-toolkit {
  max-width: 1200px;
  padding-top: 14px;
  padding-bottom: 14px;

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: center;
    padding-top: 14px;
    padding-bottom: 14px;

    .header-tooltip {
      display: flex;
      flex-direction: row;
      align-items: center;

      .sub-header {
        display: flex;
        justify-content: space-between;

        .metric-sequence {
          width: 30px;
          font-family: $orderly-font-bold;
          text-align: center;
        }
      }

      h2 {
        font-size: 24px;
        font-family: $orderly-font-bold;
      }

      .toolTip-container {
        @media only screen and (max-width: 500px) {
          .tooltip-content {
            position: relative;

            .tooltip-show {
              margin: 0;
              width: 200px;
              top: 0;
              right: 46px;
            }
          }
        }
      }
    }

    .balance {
      width: 200px;
    }

    .sort-options {
      width: 250px;
      font-size: 15px;
    }

    select {
      background: $orderly-toolkit-grey;
      border: none;
      height: 40px;
      width: 225px;
      margin-left: 10px;
      margin-top: 5px;
      margin-bottom: 5px;
      padding: 0 0 0 20px;
    }
  }

  .delete {
    width: 100%;
    text-align: end;

    img {
      width: 30px !important;
      height: 30px !important;
      margin: 0 10px 30px 10px;
      cursor: pointer;
    }
  }
}
