.metric-card{
    background-color: $orderly-toolkit-white;
    flex: 1 0 25%;
    margin: 14px 0 0 14px;
    font-family: 'tthoves-regular';
    font-weight: bold;
    padding: 15px;

    .metric-value{
        font-family: 'tthoves-bold';
        font-size: 22px;
        font-weight: 0;
        margin-top: 25px;
    }
}