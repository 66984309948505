.score-list-item-value-metric.ui-toolkit {
  display: flex;
  margin-bottom: 7px;
  justify-content: space-between;

  &:last-child {
    margin-bottom: 0;
  }

  .suffix {
    max-width: 100px;
  }

  .label {
    font-size: 16px;
    font-family: $orderly-font-medium;
    text-align: left;
  }

  .value {
    display: flex;
    font-size: 16px;
    font-family: $orderly-font-demibold;
    text-align: right;
    word-wrap: break-word; /* old name */
    overflow-wrap: break-word;

    img {
      width: 13px !important;
      height: 13px !important;
      margin-left: 10px;
    }
  }

  .variable {
    color: #069dd4;
  }
}
