.product-page {
  background-color: $orderly-toolkit-black2;

  .message.ui-toolkit {
    margin-top: 50px;
  }

  .modal-row {
    padding: 5px 0;
  }

  .missing-scorecards-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    @media only screen and (max-width: 565px) {
      flex-direction: column;

      .message {
        span {
          margin-bottom: 0;
        }
      }
    }

    .missing-scorecards-learn-more {
      color: #fff;
      font-family: $orderly-font-bold;
      text-decoration: underline;
      cursor: pointer;
      font-size: 14px;
    }
  }

  .header-content {
    background-color: $orderly-toolkit-black2;
    padding-top: 38px;
    padding-bottom: 14px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .content-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 1200px;
    margin: 0 40px;
  }

  .top-row {
    display: flex;
    flex-direction: row;
    color: $orderly-toolkit-white;
    justify-content: space-between;
    align-items: center;

    @media only screen and (max-width: 1000px) {
      justify-content: center;
    }

    .date-selector {
      display: flex;
      flex-wrap: nowrap;
      align-items: center;
      gap: 10px;

      .scoring-entity-dropdown {
        flex: 50%;
      }

      @media only screen and (max-width: 600px) {
        flex-wrap: wrap;
        justify-content: center;

        div {
          width: 100%;
        }
      }
    }
  }

  .title {
    color: $orderly-toolkit-white;

    @media only screen and (max-width: 1000px) {
      display: none;
    }

    .product-name {
      font-size: 24px;
      font-family: 'tthoves-bold';
    }
  }

  .area-scores {
    color: $orderly-toolkit-white;
    display: flex;
    flex-direction: row;
    flex: 1;
    justify-content: end;
    margin-right: 25px;

    @media only screen and (max-width: 1000px) {
      display: none;
    }

    .score {
      text-align: center;
      display: flex;
      flex-direction: column;
      margin-left: 30px;
    }

    img {
      width: 28px;
      height: 28px;
      margin-bottom: 5px;
    }
  }

  .bottom-row {
    display: flex;

    @media only screen and (max-width: 1000px) {
      flex-direction: column;
      align-items: center;
    }

    .wave-score {
      width: unset;
      align-items: center;

      .score-values {
        margin-bottom: 10px;
      }

      .current-score {
        font-size: 60px;
      }

      .total-score {
        font-size: 28px;
      }

      svg {
        width: 200px;
        height: 130px;
      }
    }

    .scoring-area {
      display: flex;
      color: $orderly-toolkit-black2;
      background-color: $orderly-toolkit-white;
      margin-top: 14px;
      padding: 10px;
      justify-content: space-around;
      align-items: center;
      flex: 12;
      border-radius: 5px;
      position: relative;

      @media only screen and (min-width: 1000px) {
        flex-direction: row;
      }

      @media only screen and (max-width: 1000px) {
        flex-direction: column;
        min-height: 350px;
        border-radius: 25px;
        margin-top: 30px;
        padding: 50px;
      }

      .score-wrapper {
        display: flex;
      }

      .indicators-wrapper {
        display: flex;
        flex-direction: column;
        gap: 20px;

        @media only screen and (max-width: 1000px) {
          margin-top: 20px;
          gap: 15px;
        }

        .indicator {
          color: $orderly-toolkit-black;
          padding: 12px;
          display: flex;
          gap: 6px;
          align-items: center;

          svg {
            width: 60px;
            height: 25px;

            &.bad {
              transform: rotate(180deg) translate(0, 0);
            }
          }

          .dash-amber {
            height: 12px;
          }
        }
      }

      .toolTip-container {
        position: absolute;
        bottom: 10px;
        left: 0;

        @media only screen and (max-width: 1000px) {
          bottom: 0;
          left: 0;
          top: 10px;
        }
      }
    }

    .metric-area {
      display: flex;
      flex-wrap: wrap;
      flex: 16;

      @media only screen and (max-width: 1000px) {
        margin-top: 30px;

        .metric-card {
          margin: 5px;
        }
      }
    }
  }

  .bottom-tab {
    width: 100%;
    height: 80px;
    position: sticky;
    bottom: 0;
    left: 0;
    display: none;
    background-color: $orderly-toolkit-black2;
    margin-top: 20px;
    align-items: center;
    justify-content: space-between;

    .left-section {
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin: 0 20px;
    }

    .right-section {
      display: flex;
      align-items: center;
      margin-right: 20px;
    }

    @media only screen and (max-width: 1000px) {
      display: flex;
      visibility: visible;

      .title {
        display: block;

        .product-name {
          font-size: 16px;

          @media only screen and (max-width: 500px) {
            font-size: 12px;
          }
        }

        .product-sku {
          @media only screen and (max-width: 500px) {
            font-size: 10px;
          }
        }
      }

      .area-scores {
        display: flex;

        .score {
          margin-right: 40px;
          margin-left: 0;

          @media only screen and (max-width: 500px) {
            margin-right: 20px;
            font-size: 12px;
          }

          img {
            width: 20px;
            height: 20px;

            @media only screen and (max-width: 500px) {
              width: 15px;
              height: 15px;
            }
          }
        }
      }
    }
  }
}