.input.ui-toolkit {
  text-align: unset;
  justify-self: flex-start;
  display: block;
  width: 100%;

  input {
    background: $orderly-toolkit-grey;
    border: none;
    height: 48px;
    width: 100%;
    padding: 0 20px;
    box-sizing: border-box;
    max-width: 400px;
  }

  input[type=checkbox] {
    width: unset;
    height: unset;
  }

  input[type=number] {
    width: 100px;
    margin-top: 5px;
  }

  input[type=checkbox]:checked::before {
    margin-top: 5px;
  }
}
