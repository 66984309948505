.score-area {
  .area-header {
    background-color: $orderly-toolkit-black2;
    color: $orderly-toolkit-black2;
    display: flex;
    justify-content: center;
    margin-bottom: 28px;

    @media only screen and (max-width: 1100px) {
      align-self: center;
    }

    .wrapper {
      max-width: 1000px;
      margin: 36px;

      .title {
        color: $orderly-toolkit-white;
        font-size: 48px;
        font-family: $orderly-font-bold;
        margin-bottom: 28px;

        @media only screen and (max-width: 1100px) {
          align-self: center;
        }
      }
    }

    .cards {
      display: flex;
      justify-content: stretch;

      .left-cards {
        margin-right: 28px;
        display: flex;
        flex-direction: column;
        flex-grow: 1;

        @media only screen and (max-width: 1100px) {
          margin: 0;
        }
      }

      .area-score-card {
        background-color: $orderly-toolkit-white;
        border-radius: 5px;
        flex-grow: 1;
        padding: 14px;
        display: flex;
        align-items: center;
        justify-content: space-around;
        box-shadow: 0 3px 6px #00000029;

        @media only screen and (max-width: 1100px) {
          align-self: center;
          border-radius: 25px;
          margin-bottom: -75px;
        }

        .wave-score {
          width: 176px;
          height: unset;

          .score-values {
            margin-right: 10px;
            margin-bottom: 10px;
          }

          .current-score {
            font-size: 70px;
            line-height: 70px;
          }

          .total-score {
            font-size: 28px;
          }

          svg {
            width: 176px;
            height: unset;
          }
        }

        .change {
          font-size: 50px;

          &.up {
            color: $orderly-toolkit-success;
          }

          &.down {
            color: $orderly-toolkit-red;
          }
        }
      }

      .trend-card {
        background-color: $orderly-toolkit-white;
        border-radius: 5px;

        @media only screen and (max-width: 1100px) {
          display: none;
        }
      }

      .trend-chart {
        margin-right: 30px;
        margin-top: 20px;
      }
    }
  }

  .metrics-outer {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 14px;
    margin-top: 0;

    @media only screen and (max-width: 1100px) {
      margin-top: 48px;
      padding: 30px;
    }

    .metric-container {
      max-width: 1000px;
      width: 100%;
      text-align: center;

      .metric-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 40px;
        font-family: $orderly-font-bold;
        margin-top: 26px;
        margin-bottom: 26px;

        @media only screen and (max-width: 1100px) {
          display: none;
        }
      }
    }
  }
}

.loader-style {
  display: flex;
  justify-content: center;
  align-items: center;
}