﻿.edit-data-btn {
  cursor: pointer !important;
  border: 1px solid black !important;
  margin-right: 6px;
  border-radius: 4px;
}

.edit-data-btn:hover {
  background-color: $orderly-toolkit-grey !important;
}

.table-add-entry {
  button {
    background-color: #292827 !important;
    color: #fff !important;
    border: none !important;
  }
}
