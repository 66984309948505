.user-summary.ui-toolkit {
  background: $orderly-toolkit-black;
  background-repeat: no-repeat;
  background-size: cover;
  color: $orderly-toolkit-white;
  display: flex;
  align-items: center;
  border: none;
  padding: 7px;
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 5;

  .avatar {
    color: $orderly-toolkit-black;

    .avatar-circle {
      width: 66px;
      height: 66px;
    }

    .company-logo {
      width: 24px;
      height: 24px;
    }
  }

  .user-details {
    padding: 0 14px;
    flex-shrink: 1;
    overflow: hidden;

    .name {
      font-family: $orderly-font-bold;
      font-size: 16px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .tenant {
      font-family: $orderly-font-bold;
      font-size: 16px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .owner {
      font-family: $orderly-font-regular;
      font-size: 16px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}
