.layout-contents {
  max-width: unset !important;
}

.dashboard.ui-toolkit {
  .dashboard-wrapper {
    display: flex;
    align-items: flex-start;
    justify-content: stretch;
    width: 100%;
    max-width: 1200px;

    .tenant-header {
      display: flex;
      flex-direction: row;
      justify-content: space-evenly;
      padding-bottom: 20px;
      cursor: pointer;

      img {
        width: 80px;
        height: 80px;
      }

      p {
        font-weight: bold;
        font-size: 25px;
        margin-left: 10px;
      }
    }

    .text-wrapper {
      padding: 0 10px 10px 10px;
    }

    .padding {
      padding: 25px;
      text-align: center;

      &.title {
        padding: 25px 25px 0 25px;
        text-align: center;
      }
    }

    .table-add-entry.ui-toolkit {
      padding: 10px 0 0 0;
    }

    .button.ui-toolkit {
      padding: 10px 0 0 0;
    }

    @media only screen and (max-width: 1050px) {
      flex-direction: column;
    }

    .page {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
    }

    .menu {
      margin-top: 36px;
      display: flex;
      flex-direction: column;
      align-items: stretch;
      flex-shrink: 0;
      flex-grow: 0;
      margin-right: 14px;
      width: 250px;

      button {
        background: none;
        border: none;
        border-bottom: 2px solid $orderly-toolkit-grey;
        text-align: left;
        height: 48px;
        cursor: pointer;
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 250px;

        .chevron {
          width: 7px;
          height: 7px;
          border: 1.5px solid #2e2d2c;
          border-width: 0 0 1.5px 1.5px;
          opacity: 1;
          transform: rotate(-135deg);

          @media only screen and (min-width: 834px) {
            margin-left: 8px;
          }
        }
      }
    }

    .dashboard-content {
      flex-shrink: 1;
      flex-grow: 1;
      width: 100%;
      max-width: 1600px;
      margin-top: 100px;

      @media only screen and (max-width: 1050px) {
        margin-top: unset;
      }

      button {
        background: none;
        border: none;
        cursor: pointer;
      }

      img {
        width: 50px;
        height: 50px;
      }

      img.edit {
        width: 20px;
        height: 20px;
      }
    }
  }
}

.search.ui-toolkit {
  margin-top: 25px;
}
