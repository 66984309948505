
.wave-score.ui-toolkit {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 50px;
  margin: 7px;

  .score-values {
    display: flex;
    align-items: flex-end;


    .current-score {
      font-family: $orderly-font-bold;
      font-size: 21px;
      line-height: 16px;
    }

    .total-score {
      font-family: $orderly-font-bold;
      font-size: 10px;
    }
  }

  svg {
    align-self: flex-start;
    width: 50px;
    height: 26px;

    image.score-image {
      width: 50px;
    }
  }
}
