﻿.scoring-entries-form {
  .form.ui-toolkit {
    text-align: left;
    margin: 0;
    padding: 0;
  }

  .text-wrapper {
    padding-left: 25px !important;
  }

  .actions {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;

    button {
      background: black !important;
    }

    .cancel-btn button {
      background: white !important;
      color: black;
      border: 1px solid #333 !important;
    }
  }

  .form.ui-toolkit .form-wrapper .button.ui-toolkit {
    margin-top: 0;
    width: 250px;
    display: unset;
    padding: unset;
  }
}

.scoring-icon {
  display: flex;
  align-items: center;
  flex-direction: row;
  min-height: 100px;
  max-height: 100px;

  .scoring-icon-select {
    background: #f2f2f2;
    border: none;
    height: 48px;
    width: 100%;
    padding: 0 20px;
    box-sizing: border-box;
    max-width: 400px;
  }

  .image-uploader-preview {
    padding: 0;
    margin-right: 20%;

    img {
      border-radius: 5px;
      width: 100px !important;
      height: 100px !important;
      margin-left: 120px;
      box-shadow: 1px 1px 11px -4px black;
    }
  }
}