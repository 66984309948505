.edit-user{
       .permissionLists{
           margin-left:56px;
           display:flex;
           flex-direction:column;
           justify-content:space-evenly;
           flex-wrap:wrap;

            @media only screen and (max-width: 834px){
                margin:0;
            }

           .permissionList{
               min-width:400px;
               margin:14px;

               .permissions{
                   overflow:auto;
                   max-height:400px;

                   label{
                       align-items:center;
                        display: flex;
                   }

                   input{
                        appearance: none;
                        border:2px solid #D3D3D3;
                        width:24px;
                        height:24px;
                        border-radius:2px;
                        margin-right:14px;
                        flex-shrink:0;

                        &:checked{
                            background-color: #D3D3D3;
                            position:relative;

                            &:before{
                                content:" ";
                                transform: rotate(45deg);
                                border: 2px solid black;
                                border-top:none;
                                border-left:none;
                                width:6px;
                                height:12px;
                                margin-left:6px;
                                position:absolute;
                            }
                        }
                   }
               }
           }
       }

       .button-holder{
           margin:14px;
           text-align:center;
       }
}