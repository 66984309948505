button.nav-item.ui-toolkit{
        background: $orderly-toolkit-black3;
        color:$orderly-toolkit-white;
        text-align:left;
        border:none;
        min-height:48px;
        width:100%;
        padding-left:28px;
        display:flex;
        align-items:center;
        cursor:pointer;

        font-family: $orderly-font-medium;
        font-size: 16px;

        &:hover, &:focus{
                background: $orderly-toolkit-black2;
        }


        img{
                margin-right:14px;
        }

        &:disabled{
                opacity:1;
                cursor: default;

                img{
                        opacity:0.5;
                }

                div{
                        opacity:0.5;
                }

        }
}