.page-mode-login {
  display: flex;
  flex-direction: column;
  justify-content: center;

  &.page.ui-toolkit {
    min-height: calc(100vh - 106px);
  }

  .form {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;

    .form-wrapper {
      background-color: $orderly-toolkit-white;
      border-radius: 15px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      position: absolute;
      top: -15px;

      .recaptcha-container {
        margin-bottom: -20px;
      }

      button {
        width: 100%;
        height: 48px;
        max-width: unset;
      }
    }
  }
}
