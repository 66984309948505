@import "./uiToolkit/UiToolkit.scss";

/* COMMON */
@import "./components/Common/Layout/layout.scss";
@import "./components/Common/MenuBar/menuBar.scss";
@import "./components/Common/SideMenu/sideMenu.scss";
@import "./components/Common/Footer/footer.scss";
@import "./components/Common/Error/error.scss";
@import "./components/Common/Message/message.scss";
@import "./components/Common/CronDateDropdown/cronDateDropdown.scss";
@import "./components/Common/ReportingEntityDropdown/reportingEntityDropdown.scss";
@import "./components/Common/WaveScore/waveScore.scss";
@import "./components/Common/ToolTips/ToolTips.scss";
@import "./components/Common/Avatar/avatar.scss";
@import "./components/Common/ProgressBar/progressBar.scss";
@import "./components/Common/TrendChart/scoreTrendChart.scss";
@import "./components/Common/SuggestionProgressBar/suggestionProgressBar.scss";
@import "./components/Common/Loading/loading.scss";
@import "components/Common/Accordion/accordion.scss";

/* LOGIN */
@import "./components/Login/login.scss";
@import "./components/Login/SplashHero/splashHero.scss";

/* Leaderboard */
@import "./components/Leaderboard/leaderboard.scss";
@import "./components/Leaderboard/LeaderboardEntry/leaderboardEntry.scss";
@import "./components/Leaderboard/FranchiseEntry/franchiseEntry.scss";
@import "./components/Leaderboard/Podium/podium.scss";

/* Scorecard */
@import "./components/ScoreCard/scoreCard.scss";
@import "./components/ScoreCard/UserSummary/userSummary.scss";
@import "./components/ScoreCard/SuggestionItem/suggestionItem.scss";
@import "./components/ScoreCard/AreaSummary/areaSummary.scss";

/* Score Area */
@import "./components/ScoreArea/scoreArea.scss";
@import "./components/ScoreArea/ScoreListMetric/scoreListMetric.scss";
@import "./components/ScoreArea/ScoreListItemMetric/scoreListItemMetric.scss";
@import "./components/ScoreArea/ScoreListItemValueMetric/scoreListItemValueMetric.scss";
@import "./components/ScoreArea/ScoreTextMetric/scoreTextMetric.scss";
@import "./components/ScoreArea/ScoreValueMetric/scoreValueMetric.scss";
@import "./components/ScoreArea/ScoreCardSummaryBar/scoreCardSummaryBar.scss";
@import "./components/ScoreArea/MetricTrendChart/metricChart.scss";

/* Configuration */
@import "./components/Configuration/configuration.scss";
@import "./components/Configuration/editUser.scss";
@import "./components/Configuration/scoreWeightings.scss";
@import "./components/Configuration/editRolePermissions.scss";

/* Help and FAQs */
@import "./components/Help/help.scss";
@import "./components/Help/contact.scss";
@import "./components/Help/userGuide.scss";

/* Dashboard */
@import "./components/Dashboard/dashboard.scss";
@import "./components/Dashboard/tenants.scss";
@import "./components/Dashboard/editTenant.scss";
@import "./components/Dashboard/scoringEntities.scss";
@import "./components/Dashboard/editScoringEntities.scss";
@import "./components/Dashboard/scoringItems.scss";
@import "./components/Dashboard/addScoringItems.scss";
@import "./components/Dashboard/editScoringArea.scss";

/* Product Page */
@import "./components/Product/productScoreCard.scss";
@import "./components/Product/MetricCard/metricCard.scss";

/* Not found */
@import "./components/NotFound/NotFound.scss";

/*Product Page*/
@import "./components/ProductLeaderboard/ProductLeaderboard.scss";
@import "./components/ProductLeaderboard/ProductEntry/ProductEntry.scss";

.toast-popup {
  color: black !important;
  width: 350px !important;
  background-color: #f2cd00 !important;
  font-family: $orderly-font-regular;

  .Toastify__toast-icon {
    svg {
      fill: black !important;
    }
  }

  &.error {
    background-color: #da2e1b !important;
    color: #fff !important;

    .Toastify__toast-icon {
      svg {
        fill: #fff !important;
      }
    }

    .Toastify__close-button {
      svg {
        fill: #fff !important;
      }
    }
  }
}