.score-text-metric.ui-toolkit {
  background-color: $orderly-toolkit-grey;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 14px;
  min-height: 36px;
  text-align: center;
  font-size: 18px;
  font-family: $orderly-font-regular;

  &.edit {
    justify-content: start;

    .metric-sequence {
      width: 30px;
      font-family: $orderly-font-bold;
      text-align: center;
    }

    .tooltip-toggle {
      margin-bottom: 15px;
    }

    .tooltip-show {
      right: unset !important;
      bottom: 5px;
      width: unset !important;
      top: unset;
      margin-bottom: 10px !important;
    }
  }

  &.container {
    display: flex;
  }

  img {
    height: 20px !important;
    width: 20px !important;
    margin-left: 10px;
    margin-bottom: 10px;
  }

  input {
    width: 500px;
    height: 30px;
  }

  .tooltip-content {
    position: relative;

    .tooltip-show {
      margin: 0;
      width: 200px;
      top: 0;
      right: 46px;
    }
  }
}
