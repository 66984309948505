﻿
.contact-form {
  padding: 0 10px 10px 10px;
  margin: 0;
}

.text-align-left {
  text-align: left !important;

  &.header {
    font-size: 32px;
    font-family: 'tthoves-bold';
  }
}

.link {
  cursor: pointer;
  text-decoration: underline !important;
  color: cornflowerblue !important;
}

.textbox-size {
  width: 50%;
}

.flex-display {
  display: flex;
}

.email-validate-color {
  color: red;
}

.gap {
  gap: 10%;
}
