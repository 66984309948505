.message.ui-toolkit {
  text-align: center;
  color: $orderly-toolkit-black;

  span {
    display: block;
    border: none;
    height: 48px;
    margin-bottom: 15px;
    padding: 0 15px;
  }

  &.invert {
    color: #fff;
  }
}