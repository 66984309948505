.score-list-item-metric.ui-toolkit {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  min-height: 64px;
  margin-bottom: 14px;
  box-shadow: 0 2px 2px #00000029;

  .header {
    background-color: $orderly-toolkit-black2;
    color: $orderly-toolkit-white;
    padding: 14px;
    flex-grow: 0;
    font-family: $orderly-font-bold;
    font-size: 21px;
    display: flex;
    align-items: center;
    min-width: 175px;

    &.numbered {
      min-width: 60px;
      justify-content: center;
    }

    .variable-selector {
      width: unset;
      padding: unset;
      height: 30px;
      font-family: $orderly-font-bold;
    }
  }

  .metrics {
    background-color: $orderly-toolkit-grey;
    padding: 14px;
    flex-grow: 1;
    overflow-x: auto;

    .add-metric {
      text-align: center;

      .add-metric-btn {
        width: 100%;
        background: #fff !important;
        box-shadow: 0 2px 2px #00000029;
        margin: auto;
        text-align: center;
        padding: 0;
        font-size: 16px;
        height: 25px;
      }
    }
  }
}
