.loading-wrapper {
  width: 100%;
  padding-top: 200px;

  img {
    margin: 0;
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 70px !important;
    width: 70px !important;
  }
}
