﻿.user-guide {
    .actions {
        button {
            margin: 7px 0;
            cursor: pointer;
            border: none;
            background-color: black !important;
            color: white;
            padding: 7px 14px;
        }
    }
}
