footer.ui-toolkit {
  background-color: $orderly-toolkit-yellow;
  display: flex;
  align-items: center;
  justify-content: center;

  .container {
    width: 100%;
    align-items: center;
    display: flex;
    flex-direction: column;
    font-size: 14px;

    @media only screen and (min-width: 834px) {
      flex-direction: row-reverse;
      justify-content: space-between;
      margin-left: 15px;

      .left-nav {
        display: flex;
        flex-direction: row-reverse;
      }
    }

    .message {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      margin: 12px;
      position: relative;

      a {
        text-decoration: underline;
      }

      @media only screen and (min-width: 834px) {
        flex-direction: row;
        justify-content: center;
        flex-grow: 1;

        p {
          display: block;
          text-align: center;
          position: absolute;
          right: 15px;
        }
      }
    }

    nav {
      display: flex;
      flex-direction: column;
      align-items: center;

      a {
        font-size: 14px;
        margin: 12px;
      }

      @media only screen and (min-width: 834px) {
        flex-direction: row;
      }
    }

    .copyright {
      text-align: center;
      margin: 15px 12px;
      font-size: 14px;
    }
  }
}
