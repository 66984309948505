
.podiums {
  max-width: 800px;
  width: 100%;

  .podium.ui-toolkit {
    margin-bottom: 28px;

    .positions {
      display: flex;
      justify-content: space-around;
      color: $orderly-toolkit-yellow;
      font-size: 20px;
      font-family: $orderly-font-bold;

      @media only screen and (min-width: 834px) {
        font-size: 40px;
      }

      .position,
      .score {
        text-align: center;
      }

      .score {
        font-size: 24px;

        .total {
          font-size: 18px;
          font-family: $orderly-font-regular;
        }
      }

      &.dim {
        opacity: 0.5;
        cursor: default;
      }

      .avatar {
        margin: 0;
        font-family: $orderly-font-regular;

        .avatar-circle {
          color: $orderly-toolkit-black;
          width: 66px;
          height: 66px;
          font-size: 8px;
          padding: 8px;

          @media only screen and (min-width: 834px) {
            width: 149px;
            height: 149px;
            padding: 20px;
            font-size: 14px;
          }
        }

        .company-logo {
          width: 24px;
          height: 24px;

          @media only screen and (min-width: 834px) {
            width: 53px;
            height: 53px;
          }
        }
      }

      .first {
        .avatar-circle {
          background-color: $orderly-toolkit-yellow;
        }

        &.text-lg {
          .avatar-circle {
            font-size: 45px;
          }
        }
      }

      .second {
        .avatar-circle {
          background-color: $orderly-toolkit-grey;
        }

        &.text-lg {
          .avatar-circle {
            font-size: 45px;
          }
        }
      }

      .third {
        .avatar-circle {
          background-color: $orderly-toolkit-brown;
        }

        &.text-lg {
          .avatar-circle {
            font-size: 45px;
          }
        }
      }
    }
  }
}
