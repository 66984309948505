.table-add-entry.ui-toolkit {
  display: flex;
  align-items: center;
  justify-content: flex-end;

  button {
    display: flex;
    justify-content: space-between;
    margin: 7px 0;
    cursor: pointer;
    border: none;
    background-color: $orderly-toolkit-black2;
    color: $orderly-toolkit-white;
    padding: 7px 14px;
    align-items: center;
    width: 100%;
    max-width: 220px;
    margin-bottom: 15px;

    .plus-icon {
      color: white;
      font-size: 1.25em;
      z-index: 2;
      position: relative;
    }

    .circle-plus-icon {
      z-index: 1;
      position: absolute;
      top: 1px;
      left: -7px;
      height: 20px;
      width: 20px;
      color: white;
      border: white solid 2px;
      border-radius: 100%;
      fill: none;
    }

    .label {
      flex-grow: 1;
      text-align: center;
    }

    svg {
      width: 30px;
      height: 30px;
      padding: 4px;
      transform: rotate(270deg);
      stroke: #fff;
      stroke-width: 50px;
      fill: none;
    }
  }

  &.dim {
    opacity: 0.5;
    cursor: default;
  }
}
