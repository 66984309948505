.input-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: start;
  padding: 0 10px 10px 10px;

  .input {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    label {
      width: fit-content;
    }

    input { background: $orderly-toolkit-grey; }

    p {
      width: 300px;

      @media only screen and (min-width: 350px) {
        width: 500px;
      }

      @media only screen and (min-width: 1100px) {
        width: unset;
      }
    }
  }
}

.button.ui-toolkit {
  justify-content: center;
  margin-top: 30px;
}

.save-btn {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.error-message {
  color: red;
  align-self: center;
  font-size: 14px;
}

.invalid {
  border: solid !important;
  border-color: red !important;
  border-radius: 5px !important;
  border-width: 1px !important;
}

.invalid:focus {
  outline: red !important;
}
