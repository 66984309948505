.score-card-summary-bar.ui-toolkit{
    display:flex;
    justify-content:center;
    align-items: center;
    padding: 7px 14px;
    background: url('/assets/Background.png') center center/cover no-repeat;
    color: white;
    z-index: 5;
    position:fixed;
    bottom:0;
    left:0;
    right:0;

    @media only screen and (min-width:834px){
        display:none;
    }

    .score-card-wrapper{
        display:flex;
        justify-content:space-between;
        align-items: center;
        padding: 3.5px 14px;
        max-width:1200px;
        width:100%;
    }

    .date-selector{
        flex-shrink: 0;
        display: none;
    }

    .detail-holder{
        display:flex;
        align-items:center;
        max-width: calc( 100vw - 45px);
        margin-right:14px;

        .score{
            
            display:flex;
            flex-direction:column;
            align-items:flex-end;
            width:60px;

            .score-values{
                display:flex;
                align-items:flex-end;

                .current-score {
                    font-family:$orderly-font-bold;
                    font-size: 21px;
                    line-height: 16px;
                }

                .total-score{
                    font-family:$orderly-font-bold;
                    font-size: 10px;
                }            
            }

            svg{
                align-self: flex-start;
                width: 50px;
                height: 26px;
                image.score-image{
                    width: 50px;
                }
            }
        }

        .labelling{
            margin-left:14px;
            overflow: hidden;
            div{
                white-space: nowrap;
                font-size:16px;
                overflow: hidden;
                text-overflow: ellipsis;
                font-family:$orderly-font-medium;
            }
        }
        
        .image-holder{
            position:relative;
            margin-right:14px;

            .company-logo{
                display:block;
                border-radius:50%;
            }
        }
    }
}