﻿.scoring-entries {
  .actions {
    display: flex;
    flex-direction: column;
    justify-content: flex-start !important;

    button {
      display: flex;
      flex-direction: row-reverse;
      justify-content: space-between;
      margin: 7px 0;
      cursor: pointer;
      border: none;
      background-color: black !important;
      color: white;
      padding: 7px 14px;
      align-items: center;
      width: 30%;
      margin-bottom: 15px;

      .plus-icon {
        color: white;
        font-size: 1.25em;
        z-index: 2;
        position: relative;
      }

      .circle-plus-icon {
        z-index: 1;
        position: absolute;
        top: 1px;
        left: -7px;
        height: 20px;
        width: 20px;
        color: white;
        border: white solid 2px;
        border-radius: 100%;
        fill: none;
      }

      .label {
        flex-grow: 1;
        text-align: center;
      }
    }
  }

  padding-left: 45px !important;
}
