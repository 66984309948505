
.progress-bar.ui-toolkit {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin: 7px;

  svg {
    align-self: flex-end;
    max-width: 300px;
    height: 6px;

    @media only screen and (min-width: 1200px) {
      width: 100%;
    }

    .bar-filled {
      fill: $orderly-toolkit-yellow;
    }

    .bar-unfilled {
      fill: $orderly-toolkit-black;
    }
  }
}
