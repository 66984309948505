﻿.prompt-modal {
    .modal-blocker.ui-toolkit {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(0, 0, 0, 0.6);
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 100;

        .modal {
            background: none !important;
            padding: 15px;
            padding-bottom: 40px;
            padding-left: 80px;
            padding-right: 80px;
            max-width: 40%;

            form {
                background-color: #ffffffcc;
                margin-top: 36px;
                padding: 15px;
                padding-bottom: 40px;
                padding-left: 80px;
                padding-right: 80px;
                max-width: 95%;
                border-radius: 9px;

                .modal-title {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;

                    h2 {
                        font-size: 24px;
                        font-family: $orderly-font-bold;
                        text-align: center;
                        width: 65%;
                    }
                }

                .modal-body {
                    margin-top: 20px;
                    margin-bottom: 25px;

                    p {
                        font-size: 18px;
                        text-align: center;
                    }

                    label {
                        width: 100%;

                        input,
                        textarea {
                            width: 100%;
                        }
                    }

                    select {
                        background: $orderly-toolkit-white;
                        border: none;
                        height: 40px;
                        width: 100%;
                        border-radius: 10px;
                        padding: 0 0 0 20px;
                    }
                }

                .modal-buttons {
                    display: flex;
                    align-items: center;
                    padding-top: 15px;

                    button {
                        width: 80px;
                        border: none;
                        height: 36px;
                        font-size: 16px;
                        margin: 14px;
                        margin-bottom: 20px;
                        font-weight: normal;
                        background-color: $orderly-toolkit-black2;
                        color: $orderly-toolkit-white;
                    }

                    .negative {
                        background-color: rgba(0, 0, 0, 0.2);
                        color: black;
                    }
                }
            }
        }
    }
}
