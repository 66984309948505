﻿h2 {
  font-size: 25px;
  font-weight: bolder;
}

.accordion-title {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  background-color: #d4d4d4;
  margin: 10px 0;
  font-size: 18px;
  font-weight: 900;
}

.accordion-toggler {
  font-size: 35px;
  font-weight: bolder;
}

.accordion-title,
.accordion-content {
  padding: 1rem;
}

.accordion-content {
  background-color: #fff;
}

@media screen and (max-width: 700px) {
  body {
    font-size: 18px;
  }

  .accordion {
    width: 90%;
  }
}
