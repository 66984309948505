﻿.scoring-items-form {
  .form.ui-toolkit {
    text-align: left;
    margin: 0;
    padding: 0;
  }

  .text-wrapper {
    padding-left: 25px !important;
  }

  .actions {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;

    button {
      background: black !important;
    }

    .cancel-btn button {
      background: white !important;
      color: black;
      border: 1px solid #333 !important;
    }
  }

  .form.ui-toolkit .form-wrapper .button.ui-toolkit {
    margin-top: 0;
    width: 250px;
  }
}
