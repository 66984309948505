.suggestion-item.ui-toolkit {
  display: flex;
  align-items: center;
  padding: 7px;
  font-size: 16px;
  border: none;
  background-color: white;
  outline: none;
  cursor: pointer;
  font-family: $orderly-font-regular;
  position: relative;
  margin: 0 auto;

  .area {
    text-align: right;
    font-family: $orderly-font-medium;
    font-size: 0.8em;
    position: relative;

    &.area-0 {
      color: $orderly-toolkit-red;
    }

    &.area-2 {
      color: $orderly-toolkit-success;
    }
  }

  .suggestion-content {
    text-align: left;
    display: flex;
    justify-content: space-between;
  }

  .suggestion-link:hover {
    color: grey;
  }

  .leaf-icon {
    width: 25px;
    position: absolute;
    right: -20px;
  }

  .suggestion-score {
    margin: 7px;
    display: flex;
    place-self: flex-start;

    svg {
      width: 40px;
      height: 20px;
    }

    &.suggestion-score-0 {
      svg {
        path {
          fill: $orderly-toolkit-red;
          transform: rotate(180deg) translate(-80px, -40px);
        }
      }
    }

    &.suggestion-score-2 {
      svg {
        path {
          fill: $orderly-toolkit-success;
        }
      }
    }
  }
}
