.side-bar.ui-toolkit {
  background-color: $orderly-toolkit-black3;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 72px;

  &.expanded {
    width: 200px;
    align-items: flex-start;

    .nav-item {
      .label {
        display: block;
      }
    }
  }

  .toggle-button {
    background-color: $orderly-toolkit-black3;
    height: 62px;
    width: 72px;
    border: none;
    cursor: pointer;
    outline: none;
  }

  button.nav-item.ui-toolkit {
    background-color: $orderly-toolkit-black3;
    padding-left: 20px;
    outline: none;
  }

  .nav-item {
    color: $orderly-toolkit-white;

    .label {
      display: none;
    }
  }

  @media only screen and (max-width: 834px) {
    display: none;
  }
}
