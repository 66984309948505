
.bar-wrapper {
  width: 70%;
  position: relative;
  margin: 0 auto;

  .progress-bar {
    width: 100%;
    height: 20px;
    border-radius: 20px;
    background-color: lightgray;
    position: absolute;
    left: 0;
    right: 0;
    z-index: 1;
    margin-top: 10px;
    box-shadow: 0 3px 6px #0000004f;
  }

  .label {
    position: relative;
    display: inline-block;
    margin-top: 10px;
    font-size: 0.9em;

    &.label-left {
      position: absolute;
      left: -60px;
    }

    &.label-rightspace {
      position: absolute;
      right: -60px;
    }

    &.average {
      position: absolute;
      margin-top: 70px;
    }

    &.label-right {
      position: absolute;
      right: -50px;
    }
  }

  .progress-filler {
    z-index: 2;
    position: absolute;
    left: 0;
    height: 20px;
    border-radius: 20px;
    background-color: gray;
    width: 35%;
    margin-top: 10px;
  }

  .store {
    position: absolute;
    bottom: 20px;
  }

  .label-line {
    height: 20px;
    width: 2px;
    background-color: $orderly-toolkit-yellow;
    position: absolute;
    margin-bottom: 3px;
    bottom: -9px;

    &.avg {
      margin-bottom: 0;
      bottom: 0;
      top: 44px;
      width: 2.5px;
    }
  }

  .line {
    height: 40px;
    width: 20px;
    display: inline-block;
    position: absolute;
    z-index: 2;

    &.average {
      left: 50%;
      width: 8px;
      border-radius: 5px;
      background-color: $orderly-toolkit-yellow;
      box-shadow: 0 3px 12px #00000086;
    }
  }

  .dot {
    height: 40px;
    width: 20px;
    border-radius: 0;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
    display: inline-block;
    box-shadow: 0 3px 6px #00000029;
    position: absolute;
    z-index: 0;

    &.left {
      left: -10px;
    }

    &.right {
      right: -10px;
      transform: rotate(180deg);
    }

    &.dot-bad {
      background-color: $orderly-toolkit-red;
    }

    &.dot-good {
      background-color: $orderly-toolkit-success;
    }
  }
}

.stats {
  position: relative;
  bottom: -125px;
  font-size: 0.8em;
  left: 70px;
  display: none;
}
