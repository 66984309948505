.error.ui-toolkit {
    text-align: center;

    span {
        color: $orderly-toolkit-red;
        display: block;
        border: none;
        height: 48px;
        margin-bottom: 15px;
        padding: 0 15px;
        line-height: 48px;
    }
}
