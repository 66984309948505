﻿.help-page.ui-toolkit {
  .help-wrapper {
    display: flex;
    max-width: 1200px;
    align-items: flex-start;
    justify-content: stretch;
    width: 100%;

    .text-wrapper {
      padding: 0 10px 10px 10px;
    }

    .padding {
      padding: 25px;

      &.title {
        padding: 25px 25px 0 25px;
      }
    }

    .table-add-entry.ui-toolkit {
      padding: 10px 0 0 0;
    }

    .button.ui-toolkit {
      padding: 10px 0 0 0;
    }

    @media only screen and (max-width: 1050px) {
      flex-direction: column;
    }

    .menu {
      margin-top: 36px;
      display: flex;
      flex-direction: column;
      align-items: stretch;
      flex-shrink: 0;
      flex-grow: 0;
      margin-right: 14px;
      width: 250px;

      button {
        background: none;
        border: none;
        border-bottom: 2px solid $orderly-toolkit-grey;
        text-align: left;
        height: 48px;
        cursor: pointer;
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 250px;

        .chevron {
          width: 7px;
          height: 7px;
          border: 1.5px solid #2e2d2c;
          border-width: 0 0 1.5px 1.5px;
          opacity: 1;
          transform: rotate(-135deg);

          @media only screen and (min-width: 834px) {
            margin-left: 8px;
          }
        }
      }
    }

    .help-content {
      flex-shrink: 1;
      flex-grow: 1;
      width: 100%;
      padding: 14px;

      h2 {
        padding: 0 10px 10px 10px;
        font-size: 32px;
      }
    }
  }
}

.search.ui-toolkit {
  margin-top: 25px;
}
