﻿.dashboard-wrapper {
  max-width: 1400px !important;
}

.scoring-areas-form {
  .form.ui-toolkit {
    text-align: left;
    margin: 0;
    padding: 0;
  }

  .uploader {
    display: flex;
    flex-direction: row;

    .upload-img {
      width: unset !important;
      height: unset !important;
    }

    input[type=file] {
      display: none !important;
    }

    .image-uploader-preview {
      padding: 0;
      margin-right: 20%;

      img {
        border-radius: 5px;
        width: 100px !important;
        height: 100px !important;
        margin-left: 120px;
        box-shadow: 1px 1px 11px -4px black;
      }
    }
  }

  label {
    font-family: $orderly-font-bold;
  }

  .info {
    font-size: 20px;
    text-align: center;
    margin: 20px 0;
  }

  .section-header {
    background-color: #f2cd00;
    box-shadow: 0 2px 2px #00000029;
    padding-top: 15px;
    padding-bottom: 15px;
    font-size: 25px;
    padding-left: 10px;
  }

  .section-wrapper {
    display: none;

    &.active {
      display: unset !important;
    }
  }

  .variable-sub-title {
    width: 100%;
    text-align: center;
  }

  .tooltip-toggle {
    background-color: #f2cd00 !important;
  }

  .tooltip-show {
    max-width: 400px;
    margin: 0;
    background: #fff;
    border-radius: 0 !important;
    padding: 0;
    position: unset;

    input {
      margin: 0 !important;
      width: 350px !important;
      border: 0;
    }
  }

  .add-metric-btn {
    width: 200px;
    margin: auto;
    text-align: center;
    margin-bottom: 50px;

    button {
      width: 100%;
      height: 30px;
      font-size: 16px !important;
      background: #292827 !important;
      box-shadow: 0 2px 2px #00000029;
      color: #fff;
    }
  }

  .score {
    .change-direction-increase {
      fill: $orderly-toolkit-success;
      width: 25px;
      height: 25px;
      margin-left: 10px;

      svg {
        width: 25px;
        height: 25px;
      }

      &.change-good-false {
        fill: $orderly-toolkit-red;
      }
    }
  }

  .score-total {
    input {
      margin: 0 !important;
      display: inline-block !important;
    }
  }

  .input-container {
    p {
      font-weight: bold;
    }
  }

  .score-value-metric.ui-toolkit {
    input {
      margin: 0 !important;
    }

    .metric-sequence {
      width: 30px;
      text-align: center;
      display: unset !important;
      margin-right: 15px !important;
    }
  }

  .variables-wrapper {
    display: grid;
    grid-template-columns: auto auto;

    .variables-container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      max-width: 430px;

      input {
        height: 30px;
        font-size: 16px;
        font-family: $orderly-font-medium;
        color: #069dd4;
      }

      select {
        height: 30px;
        width: 145px;
        margin-bottom: 21px;
        margin-top: 7px;
      }
    }
  }

  .add-variable {
    text-align: center;

    button {
      font-size: 16px;
      background-color: #292827 !important;
      color: #fff;
      width: 150px;
      height: 30px;
      font-family: $orderly-font-medium;
    }
  }

  .delete-variable {
    img {
      width: 13px !important;
      height: 13px !important;
      margin-bottom: 10px;
      margin-left: 10px;
    }
  }

  .text-wrapper {
    padding-left: 25px !important;
  }

  .score-list-item-metric.ui-toolkit {
    input {
      width: 200px !important;
      font-size: 18px;
      font-family: $orderly-font-bold;
      margin: 0 !important;
    }
  }

  .score-list-metric.ui-toolkit {
    padding-top: 0 !important;

    .header {
      padding-top: 0 !important;
    }

    h2 {
      input {
        width: 220px;
        font-size: 18px;
        font-family: $orderly-font-bold;
      }
    }
  }

  .actions {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;

    button {
      background: black !important;
    }

    .cancel-btn button {
      background: white !important;
      color: black;
      border: 1px solid #333 !important;
    }
  }

  .suggestions-wrapper {
    margin-top: 50px;
    box-shadow: 0 2px 2px #00000029;
    display: grid;
    background-color: #f2f2f2;
    grid-template-columns: auto auto 50px;

    input {
      background-color: #fff;
    }

    .delete-suggestion-btn {
      margin-top: 15px;

      img {
        width: 30px !important;
        height: 30px !important;
      }
    }

    .suggestions-container {
      display: grid;
      grid-template-columns: auto;
      padding-left: 25px;
      padding-top: 15px;
    }

    .suggestions-container-2 {
      padding-left: 10px;
      display: grid;
      grid-template-columns: 120px 120px 120px 120px;
      justify-content: space-evenly;
      padding-top: 15px;

      .checkbox {
        display: flex;
        flex-direction: column;
        padding: 0 20px;

        label {
          width: 130px;
        }
      }
    }
  }

  .add-suggestion-btn {
    text-align: start;
    margin-top: 20px;

    button {
      font-size: 18px;
    }
  }

  .form.ui-toolkit .form-wrapper .button.ui-toolkit {
    margin-top: 50px;
    width: 250px;
  }

  table {
    width: 1250px !important;

    th {
      text-align: center !important;
    }

    td {
      text-align: center;

      input {
        margin: 0 5px 5px 0;
        margin-bottom: 0 !important;
        width: 50px;
        display: unset !important;

        &.text {
          width: 100px;
        }
      }
    }
  }

  .values-section-header {
    margin: 40px 0;
  }

  .raw-score-wrapper {
    display: flex;

    .raw-score-input {
      width: unset;
      height: 30px;
      font-size: 30px;
      text-align: center;
    }
  }

  .metric-values-wrapper {
    display: flex;
    flex-direction: column;

    .value-metrics-wrapper {
      display: grid;
      grid-template-columns: auto auto auto auto;
      margin-bottom: 50px;

      .value-metrics-container {
        margin-top: 15px;
        text-align: center;
        background-color: #f2f2f2;
        padding-bottom: 15px;
        margin-right: 5px;

        label {
          text-align: center !important;
        }

        input {
          margin: 0 5px 5px 0;
          margin-bottom: 0 !important;
          width: 50px;
          display: unset !important;

          &.text {
            width: 100px;
          }
        }
      }
    }

    .list-metrics-wrapper {
      display: grid;
      grid-template-columns: auto auto;

      .list-metrics-container {
        display: flex;
        flex-direction: column;

        &.add-item {
          text-align: start !important;
        }

        .list-metric-values {
          display: grid;
          grid-template-columns: auto;

          .list-metric-title {
            width: 180px;
          }

          img {
            width: 20px;
            height: 20px;
          }

          .list-metric-value {
            display: flex;

            input {
              width: unset !important;
              margin-bottom: 0 !important;
            }

            input[type=number] {
              width: 50px !important;
            }
          }
        }
      }
    }
  }
}
