.page.ui-toolkit {
  min-height: calc(100vh - 120px);

  @media only screen and (max-width: 834px) {
    min-height: calc(100vh - 270px);
  }

  &.page-mode-flex {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
  }

  &.page-number-2 {
    padding-top: 40px;
    min-height: calc(50vh);
  }

  &.page-padding {
    padding: 14px;
  }

  &.center {
    display: flex;
    justify-content: center;
  }

  .wrapper {
    max-width: 1200px;
    width: 100%;
  }
}
