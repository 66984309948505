.splash-hero.ui-toolkit{
    padding: 14px;
    background-color:$orderly-toolkit-black2;
    display:flex;
    align-items:center;
    justify-content:center;
    height:255px;

    .splash{
        margin: 14px;
        margin-bottom: 28px;
        width:330px;
        display:flex;
        flex-direction:column;
        align-items:flex-end;

        .influence{
            color: $orderly-toolkit-white;
            align-self:flex-start;
            font-size: 26px;
            margin-bottom:7px;
            
        }

        .responsibility{
            color: $orderly-toolkit-yellow;
            align-self:flex-start;
            font-size: 28px;
            font-family: $orderly-font-medium;
            margin-bottom:7px;
        }

        svg{ 
            height: 110px;
            margin-top:30px;
            path{
                fill: $orderly-toolkit-white;
                stroke: none;
            }
        }
    }
}