﻿
input {
  appearance: none;
  border: 2px solid #d3d3d3;
  width: 24px;
  height: 24px;
  border-radius: 2px;
  margin-right: 14px;
  flex-shrink: 0;

  &:checked {
    background-color: #d3d3d3;
    position: relative;

    &::before {
      content: " ";
      transform: rotate(45deg);
      border: 2px solid black;
      border-top: none;
      border-left: none;
      width: 6px;
      height: 12px;
      margin-left: 6px;
      position: absolute;
    }
  }
}

.title-center {
  text-align: center !important;
}
