.leaderboard-entry.ui-toolkit {
  display: flex;
  color: $orderly-toolkit-black;
  justify-content: space-between;
  align-items: stretch;
  min-height: 64px;
  margin: 7px 0;
  border: none;
  padding: 0;
  text-align: left;
  width: 100%;
  box-shadow: 0 2px 2px #00000029;

  @media only screen and (min-width: 834px) {
    margin: 20px 0;
  }

  .leaderboard-header {
    position: absolute;
    width: 0;
    bottom: 25px;
    font-weight: bolder;
    font-size: 20px;
  }

  &.dim {
    opacity: 0.5;
    cursor: default;

    .metrics button {
      cursor: default;
    }
  }

  .entry-header {
    position: relative;
    background-color: $orderly-toolkit-yellow;
    flex-grow: 0;
    flex-shrink: 0;
    font-family: $orderly-font-bold;
    font-size: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    min-width: 48px;
    text-align: center;
    justify-content: center;

    .streak-icon {
      img {
        width: 30px;
        position: absolute;
        top: 5px;
        right: 2px;
      }
    }

    @media only screen and (min-width: 834px) {
      font-size: 21px;
      width: 75px;
      background-color: $orderly-toolkit-black2;
      color: $orderly-toolkit-white;
    }

    .change {
      font-family: $orderly-font-regular;
      font-size: 16px;

      @media only screen and (min-width: 834px) {
        display: none;
      }
    }
  }

  .metrics {
    background-color: $orderly-toolkit-white;
    padding: 7px 0;
    flex-grow: 1;
    display: flex;
    align-items: center;
    font-family: $orderly-font-medium;
    font-size: 16px;
    text-overflow: ellipsis;
    border-radius: 0 5px 5px 0;
    border: none;
    outline: none;
    cursor: pointer;
    text-align: left;

    &:hover {
      background-color: $orderly-toolkit-grey;

      button {
        background-color: $orderly-toolkit-grey;
      }
    }

    /* Required for text-overflow to do anything */
    white-space: nowrap;
    overflow: hidden;

    .leaderboard-details-info {
      width: fit-content;
      height: fit-content;
      background: none;
      text-align: left;
      flex-grow: 1;
    }

    @media only screen and (min-width: 834px) {
      padding: 7px 14px;
      background-color: $orderly-toolkit-grey2;
    }

    .store {
      display: inline-flex;
      align-items: center;
      font-weight: bold;
      width: 100px;

      .avatar-circle {
        background-color: #ebebeb;
        display: inline-flex;
        justify-content: center;
        text-align: center;
        align-items: center;
        height: 25px;
        width: 25px;
        border-radius: 50%;
        margin-right: 1em;
      }
    }

    .areas {
      font-family: $orderly-font-bold;
      display: flex;

      @media only screen and (min-width: 834px) {
        padding: 0 14px;
        font-size: 16px;
      }

      .area-score {
        display: none;

        @media only screen and (min-width: 834px) {
          display: block;
          padding: 0 20px;
        }
      }

      .area-score.active {
        display: block;
        padding: 0 15px;

        @media only screen and (min-width: 700px) {
          width: fit-content;
          padding: 0 100px;
        }

        @media only screen and (min-width: 834px) {
          padding: 0 20px;
        }
      }

      .area-image {
        height: 25px;
        width: 25px;
      }
    }

    .details {
      flex-grow: 1;
      flex-shrink: 1;
      font-family: $orderly-font-bold;
      font-size: 10px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      @media only screen and (min-width: 834px) {
        padding: 7px 14px;
        font-size: 16px;
      }

      .owner-details {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .owner {
          font-family: $orderly-font-regular;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        a {
          color: $orderly-toolkit-black;
          font-family: $orderly-font-regular;
        }

        @media only screen and (min-width: 834px) {
          font-size: 16px;
        }
      }

      .label,
      .tenant {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        @media only screen and (min-width: 834px) {
          font-size: 12px;
        }
      }
    }

    .score {
      display: flex;
      align-items: center;

      .wave-score {
        flex-shrink: 0;

        @media only screen and (min-width: 834px) {
          padding: 7px 14px;
        }
      }
    }

    button {
      flex-shrink: 0;
      width: 23px;
      height: 30px;
      margin: 7px;
      border: none;
      border-radius: 0;
      background: none;
      cursor: pointer;

      @media only screen and (min-width: 834px) {
        width: 38px;
      }

      .chevron {
        width: 7px;
        height: 7px;
        border: 1.5px solid #2e2d2c;
        border-width: 0 0 1.5px 1.5px;
        opacity: 1;
        transform: rotate(-135deg);

        @media only screen and (min-width: 834px) {
          margin-left: 8px;
        }
      }
    }
  }
}