.score-card {
  &.embedded {
    .content {
      background-color: $orderly-toolkit-white;
      position: relative;

      .card-wrapper {
        .summary-card {
          margin-top: 36px;

          @media only screen and (min-width: 1000px) {
            margin: 0;
          }
        }
      }

      .toggle-button {
        display: flex;
        position: absolute;
        bottom: 0;
        left: calc(50% - 94px);
      }
    }
  }

  .stretch-bar {
    width: 100%;
    height: 180px;
    background-color: $orderly-toolkit-black2;
    margin-top: -1px;/* Hack to account for sub pixel whitespace showing */

    @media only screen and (min-width: 1000px) {
      display: none;
    }
  }

  display: flex;
  flex-direction: column;
  justify-content: center;

  .header {
    margin-top: 36px;

    @media only screen and (min-width: 1000px) {
      display: none;
    }

    .header-controls {
      justify-content: center;
      margin-right: 14px;
      display: none;

      .header-wrapper {
        max-width: 1000px;
        min-width: 100%;
        display: flex;
        justify-content: flex-end;
      }
    }
  }

  .collapse-button {
    width: 100%;
    font-size: 50px;
    background: #292827;
    border: 0;
    color: white;
    cursor: pointer;
    opacity: 0;

    &.show {
      opacity: 1;
      -webkit-transition: 0.5s linear;
      -moz-transition: 0.5s linear;
      -ms-transition: 0.5s linear;
      -o-transition: 0.5s linear;
      transition: 0.5s linear;
    }

    img {
      height: 50px;
      transform: rotate(180deg);

      @media only screen and (max-width: 1000px) {
        height: 25px;
      }
    }
  }

  .content {
    @media only screen and (min-width: 1000px) {
      background-color: $orderly-toolkit-black2;
      padding-top: 38px;
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .card-wrapper {
      display: flex;
      align-items: stretch;
      justify-content: center;
      max-width: 1200px;
      margin-left: 14px;
      margin-right: 14px;
    }
    // selected suggestion page

    .suggestion-wrapper {
      @media only screen and (min-width: 1000px) {
        .pie-title {
          display: none;
        }
      }

      display: flex;
      width: 100%;
      max-width: 1200px;
      padding: 20px;
      position: relative;
      margin-right: 60px;
      height: 0;
      -webkit-transition: 0.5s linear;
      -moz-transition: 0.5s linear;
      -ms-transition: 0.5s linear;
      -o-transition: 0.5s linear;
      transition: 0.5s linear;

      &.open {
        height: 800px;
        -webkit-transition: 0.5s linear;
        -moz-transition: 0.5s linear;
        -ms-transition: 0.5s linear;
        -o-transition: 0.5s linear;
        transition: 0.5s linear;

        @media only screen and (max-width: 1000px) {
          margin-top: 250px;
          margin-bottom: 220px;
        }
      }

      .column-left {
        position: relative;
        opacity: 0;
        height: 800px;

        @media only screen and (max-width: 1000px) {
          z-index: -1;
        }

        &.open {
          opacity: 1;
          -webkit-transition: 1s linear;
          -moz-transition: 1s linear;
          -ms-transition: 1s linear;
          -o-transition: 1s linear;
          transition: 1s linear;
        }

        .progress-wrapper {
          position: absolute;
          width: 100%;
          bottom: 190px;
          color: white;
          left: 0;

          .progress-title {
            margin-left: 45px;
            margin-bottom: 75px;
            font-size: 1.25em;
            font-weight: bold;
          }
        }
      }

      .column-right {
        display: none;
        height: 0;

        &.open {
          display: unset;
          height: 800px;
          -webkit-transition: 0.5s linear;
          -moz-transition: 0.5s linear;
          -ms-transition: 0.5s linear;
          -o-transition: 0.5s linear;
          transition: 0.5s linear;
        }
      }

      @media only screen and (max-width: 1000px) {
        flex-direction: column;
        justify-content: space-between;
        padding: 0;

        .recharts-wrapper {
          width: auto;
          display: flex;
          align-self: center;
        }

        .suggestion-container {
          display: none;
        }

        .pie-chart {
          background-color: #292827;
          width: auto;
          text-align: center;
          display: flex;
          justify-content: space-evenly;
          flex-direction: column;
          padding-bottom: 30px;

          .recharts-wrapper {
            width: 640px;

            @media only screen and (max-width: 500px) {
              width: 480px !important;
            }
          }

          .pie-title {
            color: white;
            font-size: 1.25em;
            font-weight: bold;
          }
        }

        .column-right {
          button {
            display: none;
          }
        }

        .suggestion-title {
          display: none;
        }

        .column-left {
          margin-top: -200px;
          align-items: stretch;
          position: unset;

          .current-suggestion {
            margin-right: 20px;
            text-align: center;
            width: auto;
            padding-bottom: 50px;

            .selected-header {
              font-size: 18px;
              padding: 30px 10px;
            }

            .text-wrapper {
              padding: 40px 30px 10px 30px;
              font-size: 0.9em;

              @media only screen and (max-width: 1000px) {
                font-size: 1em;
              }

              .selected-text {
                margin-bottom: 50px;
              }
            }
          }

          .progress-wrapper {
            .progress-title {
              margin-left: 0;
            }

            text-align: center;
            position: relative;
            background-color: #292827;
            min-height: 300px;
            bottom: unset;
            padding-top: 100px;
          }
        }
      }

      .suggestion-title {
        color: white;
        font-size: 2em;
        font-weight: bold;
        text-shadow: 0 3px 6px #00000029;

        @media only screen and (max-width: 1000px) {
          color: grey;
        }

        &.left {
          margin-left: 50px;
          display: flex;
          align-items: flex-start;
          justify-content: space-between;
        }

        &.controls {
          display: flex;
          justify-content: space-between;
          width: 100%;
        }

        .grey-color {
          color: grey;
        }
      }

      .current-suggestion {
        padding: 25px;
        width: 600px;
        margin-left: 20px;

        .selected-header {
          padding: 20px 30px;
          border-radius: 10px 10px 0 0;
          font-family: $orderly-font-bold;

          &.selected-header-2 {
            background-color: $orderly-toolkit-success;
          }

          &.selected-header-0 {
            background-color: $orderly-toolkit-red;
            color: white;
          }
        }

        .bold-text {
          font-weight: bold;
          margin-bottom: 5px;
        }

        .text-wrapper {
          padding: 30px 30px 20px 30px;
        }

        .selected-text {
          margin-bottom: 20px;
        }

        .selected-suggestion-wrapper {
          background-color: white;
          border-radius: 10px;
          box-shadow: 0 3px 6px #00000029;
          position: relative;
        }

        .leaf-icon {
          width: 30px;
          position: absolute;
          right: 20px;
          bottom: 20px;
        }
      }

      .suggestion-container {
        background-color: white;
        border-radius: 10px;
        padding: 20px;
        margin-top: 25px;
        box-shadow: 0 3px 6px #00000029;

        .suggestion-item.ui-toolkit {
          margin: 0;

          .leaf-icon {
            right: -25px;
          }
        }
      }

      .recharts-text {
        fill: white;
        font-size: 0.9em;
      }
    }
    //end
    .wave-score {
      width: unset;
      padding: 30px 30px;
      text-align: center;
      align-items: center;

      .current-score {
        font-size: 85px;
        line-height: unset;
      }

      .total-score {
        font-size: 40px;
      }

      svg {
        align-self: center;
        width: 300px;
        height: 130px;
      }
    }

    .toggle-button {
      width: 200px;
      height: 48px;
      margin-bottom: -24px;
      border: none;
      background-color: $orderly-toolkit-yellow;
      align-self: center;
      display: flex;
      justify-content: center;
      align-items: center;

      @media only screen and (min-width: 1000px) {
        display: none;
      }

      .chevron {
        width: 7px;
        height: 7px;
        border: 1.5px solid #2e2d2c;
        border-width: 0 0 1.5px 1.5px;
        opacity: 1;
        transform: rotate(-135deg);
        margin-top: 2px;
        margin-left: 14px;
      }
    }

    .summary-card {
      margin: 36px;
      margin-top: -200px;
      background-color: $orderly-toolkit-white;
      border-radius: 25px;
      box-shadow: 0 3px 6px #00000029;
      //display: flex;
      flex-direction: column;
      align-items: center;
      display: none;

      &.primary {
        display: flex;
        align-items: stretch;
      }

      @media only screen and (min-width: 1000px) {
        display: flex;
        flex-direction: row;
        margin-left: 0;
        margin-top: 0;
        border-radius: 5px;

        .wave-score {
          padding: 0;
          margin-left: 7px;

          .current-score {
            font-size: 70px;
          }

          .total-score {
            font-size: 28px;
            margin-bottom: 6px;
          }
        }
      }

      .tootipAndWaveScore {
        display: flex;
        flex-direction: column-reverse;
        justify-content: space-between;

        @media only screen and (min-width: 1000px) {
          flex-direction: column;
          width: 275px;
        }

        .box {
          height: 30px;
          width: 30px;
          display: none;

          @media only screen and (min-width: 1000px) {
            display: flex;
          }
        }

        .waveScore {
          @media only screen and (min-width: 1000px) {
            display: flex;
          }
        }
      }

      .toolTip-container,
      .box {
        display: flex;
        margin: 15px 5px;

        .tooltip-show {
          max-width: 200px;
        }
      }

      .suggestions {
        display: flex;
        flex-direction: column;
        padding: 20px;

        @media only screen and (max-width: 1000px) {
          padding-bottom: 40px;
        }

        @media only screen and (min-width: 1000px) {
          padding-left: 0;
          max-width: 320px;
          margin-top: 10px;
          margin-bottom: 10px;
        }
      }

      .suggestion-item {
        flex-grow: 1;
        width: 90%;
        padding: 10px;
        align-items: flex-start;

        svg {
          @media only screen and (min-width: 1000px) {
            width: 52px;
            height: 26px;
          }
        }
      }
    }

    .full-card {
      margin-top: -200px;
      //display: flex;
      flex-direction: column;
      align-items: center;
      display: none;

      &.primary {
        display: flex;
      }

      @media only screen and (min-width: 1000px) {
        display: flex;
        margin: 0;
        flex-grow: 1;
        align-items: stretch;
        flex-shrink: 0;
        justify-content: stretch;
        margin-bottom: 36px;
      }

      .sub-card,
      .area-card {
        background-color: $orderly-toolkit-white;
        border-radius: 25px;
        box-shadow: 0 3px 6px #00000029;

        .area-summary {
          margin-bottom: 30px;
        }
      }

      .sub-card {
        margin: 36px;
        margin-top: 0;
        margin-bottom: 7px;
        width: 90%;

        @media only screen and (min-width: 1000px) {
          display: none;
        }

        @media only screen and (max-width: 500px) {
          width: 80%;
        }
      }

      .header {
        @media only screen and (min-width: 1000px) {
          display: flex;
          flex-direction: column;
          align-items: center;
          margin-top: 0;
          width: 100%;
        }

        .header-stack {
          @media only screen and (min-width: 1000px) {
            align-self: flex-end;
          }
        }

        .header-controls {
          @media only screen and (min-width: 1000px) {
            display: block;
          }
        }

        .title {
          font-size: 22px;
          margin-left: 7px;
          color: $orderly-toolkit-white;
          font-family: $orderly-font-demibold;
          margin-bottom: 21px;

          @media only screen and (min-width: 1000px) {
            display: block;
            align-self: flex-start;
            margin: 7px;
          }
        }
      }

      .area-card {
        padding: 22px 30px;
        padding-bottom: 0;
        display: flex;
        flex-direction: column;
        align-items: stretch;
        width: 85%;
        position: relative;

        @media only screen and (min-width: 1000px) {
          margin: 0;
          border-radius: 5px;
          padding: 30px 30px;
          padding-bottom: 0;
          flex-grow: 1;
          justify-content: space-around;
        }

        .title {
          font-size: 22px;
          margin-left: 7px;
          font-family: $orderly-font-demibold;
          margin-bottom: 21px;

          @media only screen and (min-width: 1000px) {
            display: none;
          }

          .area-summary {
            margin-left: 7px;
            margin-bottom: 8px;
          }
        }

        @media only screen and (max-width: 500px) {
          width: 75%;
        }
      }
    }
  }

  .bonus-content {
    margin: 36px;
    display: none;

    @media only screen and (min-width: 1000px) {
      display: flex;
      justify-content: center;
      overflow: hidden;

      .right {
        text-align: right;
      }

      .bar {
        display: flex;
        align-items: center;
        margin-bottom: 36px;

        & > div {
          width: 50%;
        }
      }

      .wrapper {
        max-width: 1200px;
      }

      .mobile {
        .title {
          font-size: 18px;
          font-family: $orderly-font-bold;
          margin-bottom: 21px;
        }

        .description {
          margin-bottom: 21px;
        }

        ul li {
          margin-bottom: 14px;
        }
      }

      .trend {
        margin-bottom: 36px;

        .title {
          font-size: 40px;
          font-family: $orderly-font-bold;

          :last-child {
            color: $orderly-toolkit-success;
          }
        }

        svg {
          width: 105px;
          fill: $orderly-toolkit-success;
        }
      }

      .badge {
        .title {
          font-family: $orderly-font-bold;
          margin-top: 21px;
          margin-bottom: 14px;
        }
      }
    }
  }
}
