.leaderboard {
  background-color: $orderly-toolkit-black2;
  color: $orderly-toolkit-black;

  @media only screen and (min-width: 834px) {
    background-color: $orderly-toolkit-white;
  }

  @media only screen and (max-width: 834px) {
    padding: 0 14px;
  }

  .leaderboards {
    margin-top: 50px;
  }

  .header-controls {
    position: absolute;
    top: 80px;
    right: 0;
    left: 0;
    display: flex;
    justify-content: center;
    background: $orderly-toolkit-yellow;
    padding: 14px 28px;
    z-index: 2;

    @media only screen and (min-width: 834px) {
      position: relative;
      background: $orderly-toolkit-white;
      padding-bottom: 0;
    }

    .header-wrapper {
      width: 100%;
      max-width: 1500px;

      h1 {
        display: none;
        font-size: 40px;

        @media only screen and (min-width: 834px) {
          display: block;
        }
      }

      h5 {
        display: none;
        font-size: 15px;

        @media only screen and (min-width: 834px) {
          display: block;
        }
      }

      .collapse-button {
        background-color: $orderly-toolkit-black2;
        border: none;
        color: $orderly-toolkit-white;
        padding: 10px 20px;
        text-align: center;
        text-decoration: none;
        display: inline-block;
        font-size: 16px;
        margin-top: 40px;
        cursor: pointer;
        float: right;
      }

      .collapse-button:hover {
        background-color: $orderly-toolkit-black;
      }
    }

    .header-menu {
      width: 100%;
      max-width: 1500px;
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;

      @media only screen and (min-width: 834px) {
        display: inline-flex;
        justify-content: space-between;
        align-items: baseline;
        flex-direction: row;
        flex-wrap: wrap;
        align-content: space-around;
      }

      .leaderboard-selector {
        margin-bottom: 7px;

        @media only screen and (min-width: 834px) {
          width: 325px;
          margin-bottom: 0;
        }

        select {
          border: none;
          height: 46px;
          width: 100%;
          background: $orderly-toolkit-grey;
          padding: 8px;
          cursor: pointer;
          outline: none;
        }

        input {
          border: none;
          height: 30px;
          width: 95%;
          background: $orderly-toolkit-grey;
          padding: 8px;
          cursor: pointer;
          outline: none;
        }
      }


      .leaderboard-selector.area-selector {
        margin-top: 7px;

        @media only screen and (min-width: 834px) {
          width: 325px;
          margin-top: 0;
        }

        .toggle-button {
          position: relative;
          width: 90px; 
          height: 30px;
          background-color: #6d6c6c;
          border-radius: 30px;
          cursor: pointer;
          transition: background-color 0.3s;
          margin-top: 10%;
          margin-left: 70%;
        }
        
        .toggle-button:before {
          content: "";
          position: absolute;
          width: 26px;
          height: 26px;
          background-color: #4b4a4a;
          border-radius: 50%;
          top: 2px;
          left: 2px;
          transition: transform 0.3s;
        }
        
        .toggle-button.active {
          background-color: #0c0c0c;
        }
        
        .toggle-button.active:before {
          transform: translateX(60px);
        }
        
        .toggle-button-label {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          font-size: 10px;
          color: #fff;
          width: 100%; /* Added width */
          text-align: center; /* Center the text */
        }
        
      }

      .filter-by-name {
        margin-top: 7px;
        height: 30px !important;

        @media only screen and (min-width: 834px) {
          width: 325px;
          margin-top: 0;
        }
      }

      .date-selector {
        @media only screen and (min-width: 834px) {
          width: 325px;
          margin-bottom: 0;
        }
      }
    }
  }

  h1 {
    font-size: 30px;
    text-align: center;
  }

  .content {
    display: flex;
    justify-content: center;
    padding-top: 80px;

    &.podium {
      @media only screen and (min-width: 834px) {
        background-color: $orderly-toolkit-black2;
        padding: 14px;
        margin: -1px;
        margin-bottom: 28px;

        h1 {
          display: none;
        }
      }

      h1 {
        color: $orderly-toolkit-white;
      }
    }

    @media only screen and (max-width: 834px) {

      .leaderboards,
      .podiums {
        max-width: 880px;
        width: 100%;

        .message.ui-toolkit span {
          color: $orderly-toolkit-white;
        }
      }

      .podiums {
        padding-top: 220px;
      }

      .leaderboards {
        padding: 0 20px;
      }
    }

    @media only screen and (max-width: 844px) {
      .leaderboards {
        padding: 0 14px;
      }
    }
  }
}

.leaderboard-headers {
  display: grid;
  grid-template-columns: 180px auto auto 150px;
  font-family: $orderly-font-bold;
  font-size: 16px;
  width: 100%;
  color: $orderly-toolkit-black;

  @media only screen and (max-width: 834px) {
    color: $orderly-toolkit-white;
  }

  .headers-wrapper {
    display: flex;
    align-items: center;
    font-family: "tthoves-medium";
    font-size: 16px;
    text-overflow: ellipsis;
    border-radius: 0 5px 5px 0;
    white-space: nowrap;
    overflow: hidden;
    width: 100%;

    .static {
      font-family: $orderly-font-bold;
      font-size: 16px;

      &.store {
        width: 70px;
        flex-grow: 20;
      }
    }

    .static.sortable {
      cursor: pointer;
      padding-left: 35px;
      justify-content: end;
      flex-grow: 1;
    }

    .static.active {
      text-decoration: underline;
    }

    .score-areas {
      font-family: $orderly-font-bold;
      display: flex;
      font-size: 12px;
      flex-grow: 1;
      justify-content: space-evenly;
      width: 325px;

      .score-area {
        display: flex;
        justify-content: center;
        padding: 0 6px;
        max-width: 46px;
        cursor: pointer;
        flex-grow: 1;
        text-align: center;
      }

      .score-area.active {
        text-decoration: underline;
        width: 85px;
      }
    }
  }
}


/* Default width for screens smaller than 600px */

.leaderboard-wrapper {
  width: 100%;
}

/* Width for screens between 600px and 900px */
@media (min-width: 800px) and (max-width: 1200px) {
  .leaderboard-wrapper {
    width: 500px;
  }
}


/* Width for screens larger than 900px */
@media (min-width: 800px) {
  .leaderboard-wrapper {
    min-width: 700px;
  }
}

@media only screen and (min-width: 1400px) {
  .leaderboard-wrapper {
    min-width: 1200px;
  }
}



