.pagination.ui-toolkit {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 64px;
    margin: 7px 0;

    .disabled {
        display:none
     }

    .current-page {
        font-weight: bolder;
    }

    button {
        border: none;
        background: none;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 6px;
        margin: 9px;
        font-size: 14px;
        font-weight: 100;
        cursor: pointer;

        &:disabled {
            display: none;
        }
    }
}
