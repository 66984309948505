.form.ui-toolkit {
  display: flex;
  justify-content: center;
  text-align: center;
  margin: 28px;
  padding: 10px;

  @media only screen and (max-width: 834px) {
    margin: 0;
    margin-top: 28px;
  }

  .form-wrapper {
    width: 100%;

    .title {
      margin-top: 15px;
      margin-bottom: 21px;
      margin-left: 28px;
      margin-right: 28px;
      text-align: left;
    }

    .fields {
      margin: 28px;
      margin-top: 0;

      @media only screen and (max-width: 834px) {
        margin: 0;
      }

      label {
        text-align: left;
        margin-top: 7px;
        margin-bottom: 14px;
      }

      input {
        display: block;
        margin-bottom: 21px;
        margin-top: 7px;
      }
    }

    .button.ui-toolkit {
      justify-content: flex-start;

      &:first-of-type {
        margin-top: 21px;
      }
    }
  }
}
