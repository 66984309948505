.avatar.ui-toolkit {
  margin: 7px;
  margin-right: 14px;
  position: relative;
  color: black !important;

  .avatar-circle {
    background-color: #ebebeb;
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;
    height: 65px;
    width: 65px;
    border-radius: 50%;
  }

  .company-logo {
    background-color: $orderly-toolkit-white;
    display: block;
    height: 20px;
    width: 20px;
    border-radius: 50%;
    position: absolute;
    right: -5px;
    bottom: 0;
  }
}
