.area-summary.ui-toolkit {
  display: flex;
  align-items: center;
  font-size: 12.5px;
  font-family: $orderly-font-medium;

  img {
    margin-right: 7px;
    flex-grow: 0;
    flex-shrink: 0;
    width: 25px;
  }

  .flame-icon {
    width: 25px;
    position: absolute;
    left: 2px;
  }

  .score .img-title {
    flex-grow: 1;
    flex-shrink: 0;
    margin-left: 3px;
    min-width: 105px;
    text-align: right;
    margin-right: 25px;
  }

  .img-title {
    width: 50px;
    margin-right: 10px;
  }

  .progress-bar {
    flex-grow: 1;
    flex-shrink: 1;

    @media only screen and (max-width: 1000px) {
      margin: 0;
      margin-left: 10px;
      margin-right: 10px;
      width: 0;

      svg {
        width: 100%;
      }
    }
  }

  button {
    flex-grow: 0;
    flex-shrink: 0;
    width: 26px;
    height: 26px;
    border: none;
    background-color: $orderly-toolkit-yellow;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin-left: 8px;

    .chevron {
      width: 7px;
      height: 7px;
      border: 1.5px solid #2e2d2c;
      border-width: 0 0 1.5px 1.5px;
      opacity: 1;
      transform: rotate(-135deg);
      margin-top: 1px;
      margin-right: 3px;
    }
  }
}
