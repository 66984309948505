.chart-wrapper {
  font-size: 0.75em;

  @media only screen and (max-width: 600px) {
    display: none;
  }

  .recharts-surface {
    overflow: visible;
  }
}
