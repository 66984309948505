.table.ui-toolkit{
    width:100%;

    table{
        width:100%;
        border-collapse:collapse;
    }
    thead{

        th{
            font-family:$orderly-font-medium;
            font-size:16px;
            height:48px;
            text-align:left;
            border-spacing: 0;
            font-weight:normal;
            border:none;

        }
    }

    tbody{
        tr{
            font-family:$orderly-font-regular;
            font-size:16px;
            height:48px;
            background-color:$orderly-toolkit-grey;
            border-top: 2px solid #e7e7e7;
            cursor:pointer;

            &:nth-child(odd){
                background-color:$orderly-toolkit-white;
            }

            &.selected{
                background-color:$orderly-toolkit-yellow;
            }
        }
    }

    @media only screen and (max-width: 500px) {
        overflow-x: auto;
    }
}