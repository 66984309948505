.pointer-on-hover {
    cursor: pointer;
}

.select-wrapper select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: url('/assets/filter (1).png') no-repeat right center;
    background-color: #f2f2f2;
    background-position: calc(100% - 10px) center;
    background-size: 14px 14px;
    border: none;
    height: 40px;
    width: 150px;
    margin-left: 10px;
    margin-top: 5px;
    margin-bottom: 5px;
    padding: 0 0 0 20px;
}

.select-wrapper select option[selected] {
    font-weight: bold;
    margin-left: -5px;
}

.container-style {
    display: flex;
    align-items: center;
    position: relative;
    padding-top: 20px;
    margin-left: 10px;
    margin-bottom: 17px;
    font-weight: bold;
}

.cross-style {
    position: absolute;
    top: 0;
    right: 0;
    padding: 5px;
    background-color: transparent;
    color: white;
    border: none;
    cursor: pointer;
}

.main-container {
    background-color: #292827;
    height: 530px;
}

.graph {
    background-color: white;
    padding-top: 30px;
}


.graph-container{
    height: 420px;
    width: 96%;
    overflow: auto;
    background-color: white;
    margin-left: 20px;
    margin-bottom: 10px;
}

@media only screen and (max-width: 800px) {
    .graph-container {
    height: 420px;
        width: 95%;
        overflow: auto;
        background-color: white;
        margin-left: 20px;
        margin-bottom: 10px;
    }
}

.centered-message {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80%;
}