﻿.tenant-form-layout {
  .form.ui-toolkit {
    text-align: left;
    margin: 0;
    padding: 0;
  }

  .text-wrapper {
    padding-left: 25px !important;
  }

  .threshold-input-value {
    font-size: 18px;
    font-weight: bold;
    position: relative;
    left: 25px;
    bottom: 3px;
  }

  .input-container {
    max-width: 450px;

    p {
      font-weight: bold;
      font-size: 18px;
      margin-top: 30px;
    }

    .threshold-input-wrapper {
      display: flex;
      align-items: center;

      label {
        width: 0;
      }

      input[type="range"] {
        -webkit-appearance: none;
        height: 40px;
        width: 450px;
        background: #f5f5f5;
        border-radius: 15px;
        background-image: linear-gradient(#f2cd00, #f2cd00);
        background-repeat: no-repeat;

        @media only screen and (max-width: 500px) {
          width: 250px;
        }
      }

      input[type="range"]::-webkit-slider-thumb {
        -webkit-appearance: none;
        height: 0;
        width: 0;
        transition: background 0.1s ease-in-out;
      }
    }
  }

  .input-container > * {
    flex: 1;
  }

  .input.ui-toolkit input {
    max-width: 80%;
  }

  .form.ui-toolkit .form-wrapper .fields input[type=file] {
    width: 20px;
    display: none;
  }

  .image-uploader {
    cursor: pointer;
    border-radius: 5px;
    text-align: center;

    .upload-img {
      width: 150px !important;
      height: 110px !important;
      object-fit: none;
    }

    .red-border {
      border: 1px dashed red;
    }
  }

  .image-uploader-preview {
    padding: 0;
    margin-right: 20%;

    img {
      border-radius: 5px;
      width: 100px !important;
      height: 100px !important;
      margin-left: 120px;
      box-shadow: 1px 1px 11px -4px black;
    }
  }

  .uploader {
    margin-bottom: 10px;
    margin-left: -15px;
    display: flex;
    flex-direction: row;
  }

  .actions {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-top: 20px;
    flex-wrap: wrap;

    button {
      background: black !important;
    }

    .cancel-btn {
      background: white !important;
      color: black;
      border: 1px solid #333 !important;
    }
  }

  .form.ui-toolkit .form-wrapper .button.ui-toolkit {
    margin-top: 0;
    width: 250px;
  }
}

.tenant-icon {
  display: flex;
  align-items: center;
  flex-direction: row;
  min-height: 100px;
  max-height: 100px;

  .tenant-icon-select {
    background: #f2f2f2;
    border: none;
    height: 48px;
    padding: 0 20px;
    box-sizing: border-box;
    min-width: 80%;
    max-width: 80%;
  }

  .image-uploader-preview {
    padding: 0;
    margin-right: 20%;

    img {
      border-radius: 5px;
      width: 100px !important;
      height: 100px !important;
      margin-left: 120px;
      box-shadow: 1px 1px 11px -4px black;
    }
  }
}