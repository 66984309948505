.button.ui-toolkit {
  display: flex;
  justify-content: flex-end;

  button {
    background: $orderly-toolkit-black;
    color: $orderly-toolkit-white;
    border: none;
    width: 100%;
    max-width: 220px;
    margin-bottom: 15px;
    padding: 7px 14px;
    cursor: pointer;
    height: 49px;

    @media only screen and (max-width: 834px) {
      max-width: unset;
      height: 48px;
    }

    &:disabled {
      opacity: 0.5;
      cursor: default;
    }
  }
}
