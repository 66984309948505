.list.ui-toolkit{

    margin:28px;
    margin-top:0;
    padding: 10px;
    min-height: calc(100vh - 540px);
    flex-grow: 1;

    .list-alignment{
        text-align:center;
        min-height: calc(100vh - 540px);
    }
    
    .list-items{
        display:inline-flex;
        flex-direction:column;
        min-height: calc(100vh - 540px);
        justify-content: space-evenly;
        max-width: 1200px;
    }
 }